import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/logo_dark.png'
import pic_rob from '../assets/images/rob.jpg'
import pic_thad from '../assets/images/thad.png'
import pic_yikai from '../assets/images/yikai.jpg'
import pic_matthijs from '../assets/images/matthijs.jpeg'
import pic_covid_omics_kitchen from '../assets/images/covid-omics-kitchen.png'
import pic_miRNA_Atlas from '../assets/images/thumbnail_miRNA_atlas.png'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="KitchenLab" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        {/* 

              RESEARCH
              
           */}
        <div id="main">
          <section id="research" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Research</h2>
                </header>
                <p>
                  The lab specialises in computational biology, functional
                  genomics, and non-invasive diagnostics. With a focus on the
                  computational and statistical analyses of RNA, protein,
                  and metabolites produced by healthy and unhealthy
                  tissues/cell-types we define markers of various diseases that
                  can be detected in blood, urine, and cerebrospinal fluid. We
                  apply state-of-the-art statistical/machine-learning approaches
                  and leverage the massive power and flexibility of
                  cloud-computing to define novel biomarkers of cardiovascular
                  disease, type 2 diabetes, neurodegenerative diseases, and
                  various cancers. Recently the lab have become active
                  contributors to Mass-General Brigham’s COVID-19 Innovation
                  effort, helping to evaluate low-cost serologic
                  (antibody/immunity) and diagnostic (viral RNA/protein
                  detection) technologies before they are deployed to the
                  greater Boston area and beyond.
                </p>
                <ul className="actions">
                  <li>
                    <Link to="/research" className="button special">
                      Learn More
                    </Link>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>
          </section>

          {/* 

              SOFTWARE & DATA
              
           */}
          <section id="software_data" className="main special">
            <header className="major">
              <h2>Software &amp; Data</h2>
            </header>
            <ul className="features">
              <li className="clickableAwesomeFont">
                <Link
                  to="/software"
                  style={{ 'text-decoration': 'none', border: '0' }}
                >
                  <span className="icon major style1 fa-code"></span>

                  <h3>
                    <b>Software</b>
                  </h3>
                  <p>
                    Tools for processing exRNA and small-RNA-seq (<b>exceRpt</b>
                    ), and deep integration of RNA-seq and proteome data (
                    <b>EMpire</b>)
                  </p>
                </Link>
              </li>
              <li className="clickableAwesomeFont">
                <Link
                  to="/webapps"
                  style={{ 'text-decoration': 'none', border: '0' }}
                >
                  <span className="icon major style5 fa-bar-chart"></span>

                  <h3>
                    <b>WebApps</b>
                  </h3>
                  <p>
                    Interactive apps including the COVID-19 prevalence app and
                    the miRNA tissue atlas
                  </p>
                </Link>
              </li>
              <li className="clickableAwesomeFont">
                <Link
                  to="/research"
                  style={{ 'text-decoration': 'none', border: '0' }}
                >
                  <span className="icon major style3 fa-database"></span>

                  <h3>
                    <b>Data</b>
                  </h3>
                  <p>
                    APIs, including the miRNA tissue atlas as well as static
                    data driving various publications
                  </p>
                </Link>
              </li>
            </ul>
          </section>

          {/* 

              NEWS & PUBLICATIONS 
              
           */}
          <section id="news" className="main special">
            <header className="major">
              <h2>News &amp; Publications</h2>
            </header>

            <div class="blog-card">
              <div class="meta photo">
                <img
                  src={pic_covid_omics_kitchen}
                  width="200"
                  height="200"
                ></img>
              </div>
              <div class="description">
                <h1>COVID-19 antibody test performance</h1>
                <p>
                  In an effort to support the work evaluating technologies for
                  serology (antibody) testing, the lab has developed an
                  interactive webapp to explore the effect of changing
                  prevalence on the population-level performance of these tests.
                </p>
                <p></p>
                <ul className="actions small_card">
                  <li>
                    <Link
                      to="https://covid.omics.kitchen"
                      className="button small"
                      target="_blank"
                    >
                      covid.omics.kitchen
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://covidinnovation.partners.org/evaluation/"
                      className="button small"
                      target="_blank"
                    >
                      covidinnovation.partners.org
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <p></p>
            <div class="blog-card alt">
              <div class="meta photo">
                <img src={pic_miRNA_Atlas} width="300" height="180"></img>
              </div>
              <div class="description">
                <h1>miRNA Tissue Atlas</h1>
                <p>
                  Good RNA biomarkers may be specifically expressed in tissues
                  relevant to the disease under investigation. In collaboration
                  with the van Keuren-Jensen lab at TGen, we have created a
                  human small-RNA tissue atlas.
                </p>
                <p></p>
                <ul className="actions small_card">
                  <li>
                    <Link
                      to="https://microrna-atlas.omics.kitchen"
                      className="button small"
                      target="_blank"
                    >
                      microrna-atlas.omics.kitchen
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="http://data.omics.kitchen/miRNAatlas/"
                      className="button small"
                      target="_blank"
                    >
                      Interrogate specific miRNAs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/updates" className="button special">
                    Read More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

          {/* 

              LAB & PEOPLE
              
           */}
          <section id="people" className="main special">
            <header className="major">
              <h2>Lab &amp; People</h2>
              <p>Current lab members, including visiting students</p>
            </header>

            <ul className="statistics">
              <li id="clickableAwesomeFont" className="style1">
                <Link
                  to="/people/#rkitchen"
                  style={{ 'text-decoration': 'none', border: '0' }}
                >
                  <span id="clickableAwesomeFont" className="image">
                    <img src={pic_rob} alt="" width="150" height="150" />
                  </span>
                  <br />
                  Rob <strong>Kitchen</strong>
                </Link>
              </li>

              <li className="style2">
                <Link
                  to="/people/#tsweeney"
                  style={{ 'text-decoration': 'none', border: '0' }}
                >
                  <span id="clickableAwesomeFont" className="image">
                    <img src={pic_thad} alt="" width="150" height="150" />
                  </span>
                  <br />
                  Thad <strong>Sweeney</strong>
                </Link>
              </li>

              <li className="style3">
                <Link
                  to="/people/#ykuo"
                  style={{ 'text-decoration': 'none', border: '0' }}
                >
                  <span id="clickableAwesomeFont" className="image">
                    <img src={pic_yikai} alt="" width="150" height="150" />
                  </span>
                  <br />
                  Yikai <strong>Kuo</strong>
                </Link>
              </li>

              <li className="style4">
                <Link
                  to="/people/#mdegeus"
                  style={{ 'text-decoration': 'none', border: '0' }}
                >
                  <span id="clickableAwesomeFont" className="image">
                    <img src={pic_matthijs} alt="" width="150" height="150" />
                  </span>
                  <br />
                  Matthijs <strong>de Geus</strong>
                </Link>
              </li>
            </ul>

            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/people" className="button special">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section>

          {/* 

              CONTACT
              
           */}
          <section id="contact" className="main special">
            <header className="major">
              <h2>Contact</h2>
              <p>
                Our lab is based in the Cardiovascular Research Center (CVRC)
                <br />
                at Massachusetts General Hospital and Harvard Medical School.
              </p>
            </header>

            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/contact" className="button special">
                    Lab location
                  </Link>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
