import React from 'react'

//import logo from '../assets/images/logo.svg';
import logo from '../assets/images/logo.png';

// var logoStyle = {
//     color: 'white',
//     backgroundImage: 'url(' + imgUrl + ')',
//     WebkitTransition: 'all', // note the capital 'W' here
//     msTransition: 'all', // 'ms' is the only lowercase vendor prefix
//     width: '20px',
//     border-radius: '20px'
//   };

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" style={{width: '100px', 'border-radius': '50px'}}/></span>
        <h1>Kitchen Lab</h1>
        <p>machine learning &bull; genomics &bull; biomarker discovery<br />
        </p>
    </header>
)

export default Header
